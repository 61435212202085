import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { rgba } from "polished"
import React, { useEffect, useState } from "react"
import { IoPersonCircleOutline, IoTimeOutline } from "react-icons/io5"
import styled from "styled-components"
import Button from "../components/Button"
import Layout from "../components/Layout"
import { colors } from "../components/styles"
import themesToLessons from "../components/themes-to-lessons"
import { fetchApi } from "../components/utils"
import WithSidebar from "../components/WithSidebar"

const Cover = styled.img`
  float: right;
  max-width: 50%;
  border-radius: 4px;
  margin: 0 0 2rem 2rem;
  box-shadow: ${rgba("#000", 0.1)} 0px 20px 25px -5px,
    ${rgba("#000", 0.04)} 0px 10px 10px -5px;

  @media (min-width: 800px) {
    max-width: 35%;
  }
`

const SidebarTitle = styled.div`
  font-size: 1.05rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  margin-top: 50px;
`

function DiscoverLessonsList({ level, lessons }) {
  return (
    <ul
      style={{
        listStyle: "square",
        marginLeft: "1.2rem",
        paddingLeft: 0,
        lineHeight: 1,
      }}
    >
      {lessons.map((lesson) => (
        <li>
          {lesson}
          <span
            style={{
              display: "block",
              color: colors.grey,
            }}
          >
            ({level})
          </span>
        </li>
      ))}
    </ul>
  )
}

function DiscoverLessons({ title, cycle1, cycle2, seminaries }) {
  return (
    <div>
      <SidebarTitle style={{ marginTop: 25 }}>
        Cours pour approfondir
        <br />
        {title}
      </SidebarTitle>
      {cycle1 && (
        <DiscoverLessonsList
          level={
            <>
              1<sup>er</sup> cycle
            </>
          }
          lessons={cycle1}
        />
      )}
      {cycle2 && (
        <DiscoverLessonsList
          level={
            <>
              2<sup>nd</sup> cycle
            </>
          }
          lessons={cycle2}
        />
      )}
      {seminaries && (
        <DiscoverLessonsList level="Séminaire" lessons={seminaries} />
      )}
    </div>
  )
}

export default function RecensionPage({ location }) {
  const [book, setBook] = useState([])

  const search = new URLSearchParams(location.search)
  const slug = search.get("x")

  useEffect(() => {
    fetchApi(
      `https://api.institutbiblique.be/recensions/${slug}?format=html`,
      setBook,
      () => {
        console.log("NOT FOUND")
        navigate("/recherche")
      }
    )
  }, [slug])

  return (
    <Layout>
      <WithSidebar
        main={
          <section>
            <h1>
              {book.title}
              {book.bookAuthor && (
                <span
                  style={{
                    display: "block",
                    fontSize: "0.7em",
                    color: colors.yellow,
                  }}
                >
                  {book.bookAuthor}
                </span>
              )}

              <div
                style={{
                  fontSize: "0.4em",
                  fontWeight: "normal",
                  color: colors.grey,
                  marginTop: "1em",
                }}
              >
                {book.releasedOn && (
                  <span style={{ margin: "0 .7em" }}>
                    <IoTimeOutline
                      style={{
                        marginRight: ".5em",
                        verticalAlign: "-0.12em",
                      }}
                    />
                    Sorti en {new Date(book.releasedOn).getFullYear()}
                  </span>
                )}
                {book.author?.name && (
                  <span style={{ margin: "0 .7em" }}>
                    <IoPersonCircleOutline
                      style={{
                        marginRight: ".5em",
                        verticalAlign: "-0.12em",
                      }}
                    />
                    {book.author.name}
                  </span>
                )}
              </div>
            </h1>
            <article>
              {book.bookCover && (
                <Cover src={book.bookCover} alt="Couverture" loading="lazy" />
              )}
              <div dangerouslySetInnerHTML={{ __html: book.text }} />
            </article>
          </section>
        }
        side={
          <>
            <StaticImage
              style={{
                margin: "0 auto 50px",
                maxWidth: 170,
              }}
              layout="fullWidth"
              alt="Institut Biblique de Bruxelles"
              src={"../images/ibb-logo-color.svg"}
              placeholder="blurred"
            />

            {book.themes?.length &&
              book.themes.map((theme) => {
                const themeToLesson = themesToLessons[theme.slug]
                if (themeToLesson)
                  return (
                    <DiscoverLessons
                      title={themeToLesson.title}
                      cycle1={themeToLesson.cycle1}
                      cycle2={themeToLesson.cycle2}
                      seminaries={themeToLesson.seminaries}
                    />
                  )
                else return null
              })}

            <p
              style={{
                marginTop: 25,
                fontWeight: 600,
                fontSize: "1.1em",
                lineHeight: 1.2,
              }}
            >
              La plupart des séries de cours sont également proposées dans la
              filière du samedi.
            </p>

            <a href="https://www.institutbiblique.be/agenda" className="button">
              <Button ibb style={{ display: "block" }}>
                Vérifier les horaires
              </Button>
            </a>

            {book.themes?.length && (
              <>
                <SidebarTitle>Ressources sur le thème…</SidebarTitle>
                {book.themes.map((theme) => (
                  <p>
                    <a
                      href={`https://www.institutbiblique.be/theme?x=${theme.slug}`}
                    >
                      <Button ibb style={{ display: "block" }}>
                        {theme.name}
                      </Button>
                    </a>
                  </p>
                ))}
              </>
            )}
          </>
        }
      />
    </Layout>
  )
}
