const themesToLessons = {
  "pourquoi-l-etudier-pratique-des-etudes": {
    title: "la pratique des études",
    cycle1: ["Grec 1", "Hébreu 1"],
    cycle2: ["Grec 2-3", "Hébreu 2-3"],
  },

  "hermeneutique-methodes-d-exegese": {
    title: "l'herméneutique",
    cycle1: ["Herméneutique", "Méthodes d'exégèse"],
    seminaries: ["Utilisation de l'informatique dans les recherches bibliques"],
  },

  "theologie-biblique": {
    title: "la théologie biblique",
    cycle1: ["Théologie Biblique 1"],
    cycle2: [
      "Théologie Biblique de la Mission",
      "Théologie Biblique du culte de la loi et du sacrifice",
    ],
  },

  "ancien-testament": {
    title: "l'Ancien Testament",
    cycle1: ["Introduction aux 2 testaments", "Esaïe"],
    cycle2: [
      "Pentateuque",
      "Prophètes antérieurs",
      "Prophètes postérieurs",
      "Psaumes",
      "Littérature de la sagesse",
      "Daniel et Esdras-Néhémie",
    ],
    seminaries: [
      "Deutéronome en une journée",
      "Le chrétien et la loi de Moïse",
    ],
  },

  "nouveau-testament": {
    title: "le Nouveau Testament",
    cycle1: ["Introduction aux deux testaments", "Evangile de Marc", "Romains"],
    cycle2: [
      "Evangile de Jean",
      "Actes",
      "1 Corinthiens",
      "Epîtres de la captivité",
      "Evangile de Luc",
      "Epître aux Hébreux",
      "Epîtres pastorales (1-2 Timothée, Tite)",
      "Epîtres générales (Jacques, 1-2 Pierre, 1-3 Jean, Jude) et Apocalypse",
      "Théologie de l'apôtre Paul",
    ],
  },

  doctrine: {
    title: "la doctrine",
    cycle1: ["Bibliologie et survol de la doctrine", "Théologie de la Réforme"],
    seminaries: ["Hiérarchie des doctrines"],
  },

  "doctrine-doctrine-des-ecritures-bibliologie": {
    title: "la doctrine des les Écritures (Bibliologie)",
    cycle1: ["Bibliologie"],
  },

  "doctrine-attributs-de-dieu-creation": {
    title: "les attributs de Dieu et la création",
    cycle2: ["Doctrine de Dieu"],
  },

  "doctrine-trinite-et-personne-du-christ": {
    title: "la Trinité et la personne du Christ",
    cycle2: ["Christologie", "Sectes"],
  },

  "doctrine-humanite-et-peche": {
    title: "l'humanité et le péché",
    cycle2: ["Doctrine de l'humanité et du salut"],
    seminaries: ["Assurance du salut"],
  },

  "doctrine-justification-ordre-du-salut": {
    title: "la justification et l'ordre du salut",
    cycle1: ["Catholicisme"],
  },

  "doctrine-sanctification-oeuvre-du-saint-esprit": {
    title: "la sanctification et l'œuvre du Saint-Esprit",
    cycle2: ["Doctrine du Saint-Esprit"],
  },

  "doctrine-ecclesiologie-sacrements": {
    title: "l'écclésiologie et les sacrements",
    cycle2: ["Ecclésiologie"],
  },

  "doctrine-eschatologie": {
    title: "l'eschatologie",
    cycle2: ["Eschatologie"],
    seminaries: ["Le millenium"],
  },

  "histoire-de-l-eglise-theologie-historique": {
    title: "l'histoire de l'Église et la théologie historique",
    cycle1: ["Théologie de la Réforme"],
    cycle2: [
      "Histoire de l'Eglise primitive",
      "Histoire du protestantisme en Belgique",
      "Histoire de l'Eglise depuis la Réforme",
    ],
  },

  ethique: {
    title: "l'éthique",
    cycle2: ["Éthique"],
  },

  apologetique: {
    title: "l'apologétique",
    cycle2: ["Apologétique", "Sectes"],
    seminaries: ["L'apologétique et l'islam"],
  },

  archeologie: {
    title: "l'archéologie",
    cycle1: ["Introduction aux 2 testaments"],
  },

  "theologie-pratique-la-predication": {
    title: "La prédication",
    cycle1: ["Laboratoire de prédication", "Homilétique"],
    cycle2: ["Laboratoires de prédication"],
    seminaries: ["Comment préparer une prédication biblique"],
  },

  "theologie-pratique-ministere-pastoral-de-la-parole-et-leadership": {
    title: "le ministère pastoral, de la parole et du leadership",
    cycle1: ["Atelier biblique", "Ministère pastoral"],
    cycle2: ["Préparation aux épreuves dans le ministère pastoral"],
    seminaries: ["Le mentorat et le ministère de la parole exercé par tous"],
  },

  "theologie-pratique-croissance-de-l-evangile-et-implantation-d-eglises": {
    title: "la croissance de l'Évangile et l'implantation d'Églises",
    cycle2: ["Croissance de l'Évangile et Implantation d'Églises"],
  },

  "theologie-pratique-relation-d-aide": {
    title: "la relation d'aide",
    cycle2: ["Relation d'aide 1 et 2"],
  },

  "theologie-pratique-mariage-celibat": {
    title: "le mariage et le célibat",
    seminaries: ["Théologie et pratique du mariage"],
  },

  "theologie-pratique-musique": {
    title: "la musique",
    seminaries: ["La musique dans l'Église"],
  },

  "theologie-pratique-jeunes-enfants": {
    title: "les jeunes et les enfants",
    cycle2: ["Ministère parmi les jeunes", "Ministère parmi les enfants"],
  },

  "theologie-pratique-sociologie": {
    title: "Sociologie",
    seminaries: ["Le chrétien et les réseaux sociaux"],
  },

  "vie-chretienne-priere-jeune": {
    title: "la prière et le jeûne",
    seminaries: ["La prière"],
  },

  "vie-chretienne-evangelisation": {
    title: "l'évangélisation",
    seminaries: ["Évangélisation"],
  },

  "vie-chretienne-piete-croissance-spirituelle": {
    title: "la piété et la croissance spirituelle",
    cycle2: ["Piété personnelle"],
    seminaries: ["Comment connaître la volonté de Dieu pour sa vie"],
  },

  "vie-chretienne-autre": {
    title: "la vie chrétienne",
    seminaries: ["Christianisme et persecution"],
  },
}

export default themesToLessons
